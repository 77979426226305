export const forwardOrderStatus = {
    DRC: "Data Received",
    OFP: "Out For Pickup",
    PND: "Pickup Not Done",
    PKD: "Picked",
    IT: "IT",
    RAD: "Reached at Destination",
    OFD: "Out for Delivery",
    DLVD: "Delivered",
    UD: "Undelivered",
    RTON: "RTO Notified",
    RTO: "RTO",
    "RTO-IT": "RTO-IT",
    RAO: "Reached At Origin",
    "RTO-OFD": "RTO Out For Delivery",
    RTD: "RTO Delivered",
    RTU: "RTO Undelivered",
    PUD: "Pickup done",
    STD: "ShipmentTransit",
    STG: "Shortage",
    UD: "Undelivered",
    DL: "Delivered",
    RT: "Returned",
};

export const createPredictionMessage = (data) => {
    if (Object.keys(data).length === 0) {
        return "All items have been dispatched. If you can't see tracking details for any of the products, please allow some time till we update in our system.";
    }
    //Predict dispatch date
    let message = "";
    // Every product in order is a key in this object
    for (const key in data) {
        const sku = data[key];

        if (sku.inventory > 0) {
            message += `Your order item ${
                key + "(" + sku.size + ")"
            } will be dispatched within 24hrs.${"<br />"} `;
        } else {
            const size = sku.size; // order.size;
            const poCount = sku.po?.PO[size];
            const finishingCount = sku.po?.finishing[size];
            const stitchingCount = sku.po?.stitching[size];
            const cuttingCount = sku.po?.cutting[size];
            if (poCount > 0) {
                message += `Your order item ${
                    key + "(" + sku.size + ")"
                } will be dispatched within 48 hrs.${"<br />"}`;
            } else if (finishingCount > 0) {
                message += `Your order item ${
                    key + "(" + sku.size + ")"
                } will be dispatched within 48 hrs.${"<br />"} `;
            } else if (stitchingCount > 0) {
                message += `Your order item ${
                    key + "(" + sku.size + ")"
                } will be dispatched within 72 hrs.${"<br />"} `;
            } else if (cuttingCount > 0) {
                message += `Your order item ${
                    key + "(" + sku.size + ")"
                } will be dispatched within 96hrs.${"<br />"} `;
            } else {
                message += `Your order item ${
                    key + "(" + sku.size + ")"
                } will be dispatched within 7 days.${"<br />"} `;
            }
        }
    }
    return message;
};

import React, { useEffect } from "react";
import {
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { forwardOrderStatus } from "../utils/trackingUtils";

const TrackDialogComponent = ({ setShowDialog, selectedOrder, isMobile }) => {
    const dialogStyle = {
        maxWidth: "80%",
        margin: "10px",
    };

    const timelineStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "16px",
    };

    const timelineEventStyle = {
        marginBottom: "12px",
        display: "flex",
        alignItems: "center",
    };

    const dotStyle = {
        width: "12px",
        height: "12px",
        borderRadius: "50%",
        backgroundColor: "darkgreen",
        marginRight: "8px",
    };

    return (
        <Dialog
            open
            onClose={() => setShowDialog(false)}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle
                style={{
                    backgroundColor: "#313132",
                    color: "#e8ef4a",
                    fontWeight: "900",
                    textAlign: "center",
                }}
            >
                Shipping Details
            </DialogTitle>
            <DialogContent style={{ ...dialogStyle, padding: "20px" }}>
                {selectedOrder && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "20px",
                        }}
                    >
                        <Typography
                            variant="h8"
                            style={{
                                color: "#313132",
                                background: "#e8ef4a",
                                fontWeight: "800",
                                margin: "3px",
                                padding: "2px",
                                borderRadius: "3px",
                            }}
                        >{`Courier: ${selectedOrder.courierName}`}</Typography>
                        <Typography
                            variant="h8"
                            style={{
                                color: "#313132",
                                background: "#e8ef4a",
                                fontWeight: "800",
                                margin: "3px",
                                padding: "2px",
                                borderRadius: "3px",
                            }}
                        >{`AWB: ${selectedOrder.AWBNo}`}</Typography>
                        <Typography
                            variant="h8"
                            style={{
                                color: "#313132",
                                background: "#e8ef4a",
                                fontWeight: "800",
                                margin: "3px",
                                padding: "2px",
                                borderRadius: "3px",
                            }}
                        >{`Fulfillment ID: ${selectedOrder.OrderNo}`}</Typography>
                    </div>
                )}
                <div style={timelineStyle}>
                    {selectedOrder &&
                        selectedOrder.ShipmentSummary.map((event, index) => (
                            <div key={index} style={timelineEventStyle}>
                                <div style={dotStyle} />
                                {event.StatusDateTime && (
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: "#313132",
                                            marginRight: "20px",
                                            flexShrink: 0,
                                        }}
                                    >
                                        {event.StatusDateTime}
                                    </Typography>
                                )}
                                {event.StatusDate && (
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: "#313132",
                                            marginRight: "20px",
                                            flexShrink: 0,
                                        }}
                                    >
                                        {event.StatusDate}
                                    </Typography>
                                )}
                                <div
                                    style={{
                                        marginLeft: "12px",
                                        textAlign: "left",
                                        display: "flex",
                                        flexDirection: isMobile
                                            ? "column"
                                            : "row",
                                        alignItems: isMobile
                                            ? "flex-start"
                                            : "center",
                                    }}
                                >
                                    {event.StatusCode && (
                                        <Typography
                                            variant="body2"
                                            style={{
                                                color: "#313132",
                                                marginRight: "20px",
                                                flexShrink: 0,
                                                minWidth: 200,
                                            }}
                                        >
                                            {selectedOrder.courierName ===
                                            "XpressBees"
                                                ? forwardOrderStatus[
                                                      event.StatusCode
                                                  ]
                                                : selectedOrder.courierName ===
                                                  "Delhivery"
                                                ? event.StatusCode +
                                                  " - " +
                                                  forwardOrderStatus[
                                                      event.ScanType
                                                  ]
                                                : event.StatusCode +
                                                  " - " +
                                                  event.Scan}
                                        </Typography>
                                    )}

                                    {event.Location && (
                                        <Typography
                                            variant="body2"
                                            style={{
                                                color: "#313132",
                                                marginRight: "20px",
                                                flexShrink: 0,
                                            }}
                                        >
                                            {event.Location}
                                        </Typography>
                                    )}
                                    {event.ScannedLocation && (
                                        <Typography
                                            variant="body2"
                                            style={{
                                                color: "#313132",
                                                marginRight: "20px",
                                                flexShrink: 0,
                                            }}
                                        >
                                            {event.ScannedLocation}
                                        </Typography>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowDialog(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TrackDialogComponent;

export const getCustomerOrders = async (identifier, isOrderNo) => {
    // Make API call to fetch customer orders
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/getorderdetailsfromshopify";
    try {
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ identifier, isOrderNo }),
        });

        const data = await response.json();
        if (data.statusCode === 200) {
            return {
                status: 200,
                data: data.body.map((order) => ({
                    id: order.name.slice(1),
                    gid: order.id,
                    date: new Date(order.createdAt).toDateString(),
                    amount: `₹ ${order.currentTotalPriceSet.presentmentMoney.amount}`,
                    dispatched:
                        order.displayFulfillmentStatus ===
                            "PARTIALLY_FULFILLED" ||
                        order.displayFulfillmentStatus === "FULFILLED",
                    cancelled: order.tags?.includes("manual_cancel"),
                    rto: order.tags?.includes("RTO"),
                })),
            };
        } else {
            return {
                status: 500,
                message:
                    "No Orders Found Please use the emil or phone number you used while creating the order",
            };
        }
    } catch (error) {
        return {
            status: 500,
            message: "Server error please try again later",
            error,
        };
    }
};

export const getPOandStockToPredict = async (gid) => {
    // Make API call to fetch customer orders
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shopifycustomer/dispatchpredictiontocustomers";
    try {
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ gid }),
        });

        const data = await response.json();
        if (data.statusCode === 200) {
            return {
                status: 200,
                data: data.body,
            };
        } else {
            return {
                status: 500,
                message:
                    "No Orders Found Please use the emil or phone number you used while creating the order",
            };
        }
    } catch (error) {
        return {
            status: 500,
            message: "Server error please try again later",
            error,
        };
    }
};

import React, { useState, useEffect } from "react";
import { Alert } from "@mui/material";
import {
    CancelOutlined,
    CheckCircleOutline,
    WarningAmberOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getPOandStockToPredict } from "../actions/orderListLambda";
import { createPredictionMessage } from "../utils/trackingUtils";

const DeliveryStatus = ({ order }) => {
    const [message, setMessage] = useState("Searching...");
    const navigate = useNavigate();
    const handleTrackOrder = async () => {
        navigate("/trackyourorder", {
            state: { orderId: order.id },
        });
    };

    useEffect(() => {
        if (order.rto || order.cancelled || order.dispatched) {
            return;
        }
        if (order && order.gid) {
            getDetails(order);
        }
    }, [order]);

    const getDetails = async (order) => {
        const response = await getPOandStockToPredict(order.gid);
        const messageResponse = createPredictionMessage(response.data);
        setMessage(messageResponse);
    };

    function createMarkup() {
        return { __html: message };
    }

    if (order.rto) {
        return (
            <Alert
                icon={<WarningAmberOutlined fontSize="inherit" />}
                severity="warning"
            >
                Delivery didn't received
            </Alert>
        );
    } else if (order.cancelled) {
        return (
            <Alert
                icon={<CancelOutlined fontSize="inherit" />}
                severity="error"
            >
                Cancelled
            </Alert>
        );
    } else if (order.dispatched) {
        return (
            <>
                <button
                    style={{
                        padding: "8px 16px",
                        backgroundColor: "#007BFF",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "14px",
                        cursor: "pointer",
                        transition: "background-color 0.3s",
                    }}
                    onMouseOver={(e) =>
                        (e.target.style.backgroundColor = "#0056b3")
                    }
                    onMouseOut={(e) =>
                        (e.target.style.backgroundColor = "#007BFF")
                    }
                    onClick={handleTrackOrder}
                >
                    Track
                </button>
            </>
        );
    } else {
        return (
            <Alert
                icon={<CheckCircleOutline fontSize="1.5rem" />}
                severity="success"
                sx={{
                    color: "red",
                    fontSize: "1.2rem",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {message && <div dangerouslySetInnerHTML={createMarkup()} />}
            </Alert>
        );
    }
};

export default DeliveryStatus;

import { getCustomerOrders, getPOandStockToPredict } from "./orderListLambda";

export const getOrderByAWB = async (awb) => {
    let res;
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/orderbyawb";
    try {
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({ awb }),
        });

        const data = await response.json();
        if (data.statusCode === 200) {
            res = {
                status: 200,
                data: data.body,
            };
        } else {
            res = {
                status: 500,
                data,
            };
        }
    } catch (err) {
        res = { status: 500, data: err };
    }

    return res;
};

export const getOrderByOrderId = async (id) => {
    let res;
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/orders/ordersbyoriginalorderid";
    try {
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({ id }),
        });

        const data = await response.json();
        if (data.statusCode === 200) {
            res = {
                status: 200,
                data: data.body,
            };
        } else {
            res = {
                data: false,
            };
        }

        // get gid for prediction
        try {
            const gidResponse = await getCustomerOrders(id, true);
            const gid = gidResponse?.data[0]?.gid;

            const predictionResponse = await getPOandStockToPredict(gid);

            res.prediction = predictionResponse?.data;
            res.status = 200;
        } catch (error) {
            res.prediction = false;
        }
    } catch (err) {
        res = { status: 500, data: err, prediction: false };
    }

    return res;
};

export const getAllOrdersOfCustomerByPhoneNumber = async (phone) => {
    let res;
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shopifycustomer/ordersbyphone";
    try {
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({ phone }),
        });

        const data = await response.json();
        if (data.statusCode === 200) {
            res = {
                status: 200,
                data: data.body,
            };
        } else {
            res = {
                status: 500,
                data,
            };
        }
    } catch (err) {
        res = { status: 500, data: err };
    }

    return res;
};

export const getTrackingByAWBXpressBees = async (AWB) => {
    let res;
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/XBGetShipmentDetails";

    const payload = {
        orderBody: {
            AWB,
        },
        reverse: false,
    };

    try {
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        const data = await response.json();
        if (data.statusCode === 200) {
            res = {
                status: 200,
                data: data.body,
            };
        } else {
            res = {
                status: 500,
                data,
            };
        }
    } catch (err) {
        res = { status: 500, data: err };
    }

    return res;
};

export const getTrackingByAWBDelhivery = async (AWB) => {
    let res;
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/delhivery/getshipmenttracking";

    const payload = {
        AWB,
    };

    try {
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        const data = await response.json();
        if (data.statusCode === 200) {
            res = {
                status: 200,
                data: data.body,
            };
        } else {
            res = {
                status: 500,
                data,
            };
        }
    } catch (err) {
        res = { status: 500, data: err };
    }

    return res;
};

export const getTrackingByAWBShiprocket = async (AWB) => {
    let res;
    const URL =
        "https://9p3cy46ua7.execute-api.ap-south-1.amazonaws.com/beta/shiprocket/track-order";

    const payload = {
        awb: AWB,
    };

    try {
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(payload),
        });

        const data = await response.json();
        if (data.statusCode === 200) {
            res = {
                status: 200,
                data: data.body,
            };
        } else {
            res = {
                status: 500,
                data,
            };
        }
    } catch (err) {
        res = { status: 500, data: err };
    }

    return res;
};

import React, { useState, useEffect } from "react";
import {
    Container,
    Typography,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    getOrderByAWB,
    getOrderByOrderId,
    getAllOrdersOfCustomerByPhoneNumber,
} from "../actions/trackingLambda";
import { createPredictionMessage } from "../utils/trackingUtils";

import { Tab } from "@mui/icons-material";
import TrackFulfillmentComponent from "../components/TrackFulfillmentComponent";
import TrackDialogComponent from "../components/TrackDialogComponent";
import { useLocation } from "react-router-dom";

const TrackOrderPage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [searchType, setSearchType] = useState("awb");
    const [searchValue, setSearchValue] = useState("");
    const [orderDetails, setOrderDetails] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [predictionMessage, setPredictionMessage] = useState("");

    const location = useLocation();

    useEffect(() => {
        const allParams = new URL(window.location).searchParams;
        const awbId = allParams.get("awb");
        if (awbId) {
            setSearchValue(awbId);
            handleSearchSubmit(awbId);
        }
    }, []);

    useEffect(() => {
        const orderId = location.state?.orderId;
        if (orderId) {
            setSearchType("orderId");
            setSearchValue(orderId);
            handleSearchSubmit(orderId, true);
        }
    }, [location]);

    const containerStyle = {
        marginTop: "30px",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    };

    const radioGroupStyle = {
        marginBottom: "20px",
        color: "black",
    };

    const searchInputStyle = {
        marginBottom: "20px",
    };

    const handleSearchTypeChange = (event) => {
        setSearchType(event.target.value);
        setSearchValue("");
        setError("");
        setOrderDetails([]);
    };

    const handleSearchInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleSearchSubmit = async (e, flag = null) => {
        setLoading(true);
        setPredictionMessage("");
        const cleanedSearchValue = e.target
            ? searchValue.replace(/[^\w]/g, "")
            : e;

        let foundOrders = [];

        if (searchType === "orderId" || flag) {
            const response = await getOrderByOrderId(cleanedSearchValue);

            if (response.data) {
                foundOrders = response.data;
            } else {
                foundOrders = [];
            }
            // We have a prediction
            if (response.prediction) {
                const message = createPredictionMessage(response.prediction);
                setPredictionMessage(message);
            } else {
                setPredictionMessage("");
            }
        } else if (searchType === "mobile") {
            const response = await getAllOrdersOfCustomerByPhoneNumber(
                cleanedSearchValue
            );
            if (response.status === 200) {
                foundOrders = response.data;
            } else {
                foundOrders = [];
            }
        } else {
            const response = await getOrderByAWB(cleanedSearchValue);
            if (response.status === 200) {
                foundOrders = response.data;
            } else {
                foundOrders = [];
            }
        }

        if (foundOrders.length > 0) {
            if (
                searchType === "orderId" ||
                searchType === "awb" ||
                searchType === "mobile"
            ) {
                setOrderDetails(foundOrders);
            } else {
                setOrderDetails([]);
            }
            setError("");
        } else {
            if (searchType === "mobile") {
                setError(
                    `No orders are currently dispatched for the provided ${searchType}. Try searching with order id or login to your account to get general order information.`
                );
            } else if (searchType === "orderId") {
                setError("");
            } else {
                setError(
                    `No orders are currently dispatched for the provided ${searchType}`
                );
            }
            setOrderDetails([]);
        }
        setLoading(false);
    };

    function createMarkup() {
        return { __html: predictionMessage };
    }

    return (
        <Container maxWidth="md" style={containerStyle}>
            <Typography
                sx={{ fontWeight: "900", color: "#313132" }}
                variant="h4"
                gutterBottom
            >
                Track Your Order
            </Typography>

            <RadioGroup
                row
                aria-label="search-type"
                name="searchType"
                value={searchType}
                onChange={handleSearchTypeChange}
                style={radioGroupStyle}
            >
                <FormControlLabel
                    value="orderId"
                    control={<Radio />}
                    label="Order ID"
                />
                <FormControlLabel
                    value="mobile"
                    control={<Radio />}
                    label="Mobile"
                />
                <FormControlLabel value="awb" control={<Radio />} label="AWB" />
            </RadioGroup>

            <TextField
                fullWidth
                label={`Enter ${
                    searchType === "orderId"
                        ? "Order ID"
                        : searchType === "mobile"
                        ? "Mobile"
                        : "AWB"
                }`}
                variant="outlined"
                value={searchValue}
                onChange={handleSearchInputChange}
                style={isMobile ? { marginBottom: "20px" } : searchInputStyle}
            />

            <Button
                variant="contained"
                style={{
                    background: loading ? "#a88989" : "#313132",
                    color: "white",
                }}
                onClick={handleSearchSubmit}
                disabled={loading}
            >
                Search
            </Button>

            {error && (
                <Typography
                    variant="body2"
                    color="error"
                    style={{ marginTop: "10px" }}
                >
                    {error}
                </Typography>
            )}

            <div style={{ marginBottom: "20px" }}>
                {orderDetails.map((order, i) => (
                    <TrackFulfillmentComponent
                        key={i}
                        order={order}
                        setShowDialog={setShowDialog}
                        setSelectedOrder={setSelectedOrder}
                        isMobile={isMobile}
                    />
                ))}
            </div>

            {predictionMessage && (
                <div
                    style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        marginTop: "10px",
                    }}
                >
                    <CheckCircleOutline
                        color="primary"
                        sx={{ fontSize: "1.2rem" }}
                    />
                    <div style={{ fontSize: "1.2rem", color: "dodgerblue" }}>
                        {predictionMessage && (
                            <div dangerouslySetInnerHTML={createMarkup()} />
                        )}
                    </div>
                </div>
            )}

            {showDialog && (
                <TrackDialogComponent
                    setShowDialog={setShowDialog}
                    selectedOrder={selectedOrder}
                    isMobile={isMobile}
                />
            )}
        </Container>
    );
};

export default TrackOrderPage;
